@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700&display=swap');

:root{
  --headerBlack: #343434;
}

.App {
  text-align: center;
  overflow: hidden;
  font-family: 'Poppins', "sans-serif";
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input,
textarea {
  border: solid 1.5px #cac9c9;
  -webkit-transition: 1s; /* Safari */
  transition: 1s;
}

textarea:hover {
  box-shadow: 0 0 5pt 0.5pt #d3d3d3;
}
textarea:focus {
  box-shadow: 0 0 5pt 2pt #d3d3d3;
  outline-width: 0px;
}
