.dataviewer {
  padding: 32px;
  padding-top: 70px;
  position: relative;
}

.div-image,
.div-map,
.div-remark {
  text-align: left;
}

.div-back {
  margin-bottom: 16px;
  display: flex;
}

.div-text {
  border-radius: 16px;
  padding: 16px;
  height: 150px;
  box-shadow: rgb(5%) 0px 8px 24px -2px;
  border: 2px solid black;
  margin-right: 0;
  background-color: transparent;
}
.row-1 {
  margin-bottom: 28px;
}

.row-2 {
  margin-bottom: 24px;
  height: 150px;
}

.head-text {
  font-weight: bold;
  font-size: 19px;
}
.remark-text {
  color: blue;
}

.div-topic {
  font-weight: bold;
  text-align: start;
  margin-bottom: 16px;
}

.div-remark {
  margin-right: 0;
}

.react-viewer-close {
  top: 70px;
}

.react-viewer-canvas img {
  margin-top: 20px;
}
