.contact {
  background-color: white;
  height: 90vh;
  padding: 16px;
  padding-top: 110px;
  padding-left: 200px;
  padding-right: 200px;
}

.cellContainer-one {
  width: max-content;
  float: left;
  text-align: left;
}

.cellContainer-two {
  width: 40%;
  float: left;
  text-align: left;
}

.head-text {
  font-size: 2.5rem;
  font-weight: 900;
  line-height: 1;
}

.image {
  margin-top: 24px;
}

.detail-text {
  width: 100%;
  font-size: 12px;
  margin-top: 24px;
  color: #8c8c8c;
}

input {
  border: none;
  background: #f3f3f3;
  font-family: inherit;
  overflow: visible;
  width: 100%;
  text-transform: none;
  color: #495057;
  text-indent: 0px;
  text-shadow: none;
  height: 50px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 0.25rem;
  border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
}

textarea {
  border: none;
  background: #f3f3f3;
  font-family: inherit;
  overflow: visible;
  width: 100%;
  text-transform: none;
  color: #495057;
  text-indent: 0px;
  text-shadow: none;
  height: 120px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 0.25rem;
  border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
}

.input-text {
  margin-bottom: 24px;
}

.btn-submit {
  background: #000;
  color: #fff;
  cursor: pointer;
  border-radius: 4px !important;
  border: none;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.btn-submit:hover {
  background-color: #2a2a2b;
  transition: 0.8s;
}
